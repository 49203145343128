import Partials from 'components/partials/Partials';
import type { PartialWidgetContext } from 'types/cms';
import { getKey } from 'utils/idGenerator';

export type GroupGridProps = {
  readonly children: Array<PartialWidgetContext>;
};

const GroupGridArticle = ({ context }: { context: GroupGridProps }) => (
  <div className="article-body">
    {context.children.map((widget: PartialWidgetContext, index: number) => (
      <Partials key={`${getKey(widget.id, widget)}-${index}`} context={widget} />
    ))}
  </div>
);

export default GroupGridArticle;
